import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-home',
    template: `
        <div class="text-center mb-5">
            <h3>Paga la multa</h3>
            <p>Scegli il metodo di pagamento preferito</p>
        </div>

        <div class="text-center">
            <button class="btn btn-couple btn-success" (click)="checkName('bollettino')">
                Bollettino
            </button>
            <button class="btn btn-couple btn-info" (click)="checkName('pagopa')">
                PagoPA
            </button>
        </div>

        <form action="{{this.url}}" method="post" id="payform" (ngSubmit)="submit()" class="m-auto d-none">
            <input type="hidden" name="key" value="{{this.key}}">
            <input type="hidden" name="id" value="5919">
            <input type="hidden" name="type" value="{{this.type}}">
        </form>
    `,
    styles: [`
        .btn-couple {
            min-width: 180px;
            cursor: pointer;
        }
        
        @media(max-width: 500px) {
            .btn-couple {
                margin: 0 auto 20px;
            }
            .btn {
                display: block;
                width: 100%;
            }
        }
    `]
})
export class HomeComponent implements OnInit {
    type: string;
    url: string;
    key: string;

    constructor(
        private http: HttpClient
    ) {
    }

    ngOnInit(): void {
    }

    getKeys(choice) {
        return this.http.get<{key: string, url: string}>(`https://betaapi.helpmulte24.it/api/v1/thirdparties/paytipper?type=${choice}`);
    }

    checkName(choice) {
        this.type = choice;
        this.getKeys(choice).subscribe(
            (res) => {
                this.key = res.key;
                this.url = res.url;
                this.submit();
            });
    }

    submit() {
        setTimeout(function () {
            (<HTMLFormElement>document.getElementById('payform')).submit();
        }, 1000);
    }

}
